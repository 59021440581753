import React from 'react'

const NotFound = () => {
  return (
    <div className="my-auto text-center">
      <h3>Coming Soon</h3>
      <p className="lead">
        It doesn't look like this functionality is here yet. <br/>
        Watch this space for new and exciting features!
      </p>
    </div>
  )
}

export default NotFound
