import React from 'react'
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Media from "react-bootstrap/Media"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from '../components/Loading'

const Profile = () => {
  const { user } = useAuth0();

  return (
    <Container className="my-3">
      <Card>
        <Card.Body>
          <Media>
            <Image src={user.picture} roundedCircle width={82} className="mr-3" />
            <Media.Body>
              <h3>{user.name}</h3>
              Email: {user.email} <br/>
              Sub: {user.sub}
            </Media.Body>
          </Media>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
