import React from 'react';
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import { LinkContainer } from 'react-router-bootstrap'
import useSWR from 'swr'
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import fetcher from '../utils/fetcher'
import Loading from '../components/Loading'
import Error from '../components/Error'
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
// import Button from "react-bootstrap/Button"
import { FiChevronRight } from "react-icons/fi";

const Campaigns = ({ status }) => {
  // eslint-disable-next-line no-unused-vars
  const { user, getAccessTokenSilently } = useAuth0();
  const { data, error } = useSWR([`/api/v1/campaigns?status=${status}`, getAccessTokenSilently], fetcher)
 
  if (error && !data) return <Error message="Unable to fetch campaigns"/>
  if (!data) return <Loading/>

  const campaigns = data.data

  // console.log(campaigns)
  
  return (
    <React.Fragment>
      <Navbar className="navbar-secondary">
        <Nav>
          <LinkContainer end to="/campaigns">
            <Nav.Link>Active</Nav.Link>
          </LinkContainer>
          {/* <LinkContainer exact to="/campaigns/planned">
            <Nav.Link>Planned</Nav.Link>
          </LinkContainer> */}
          <LinkContainer end to="/campaigns/archived">
            <Nav.Link>Archived</Nav.Link>
          </LinkContainer>
        </Nav>

        {/* <Nav className="ml-auto">
          <LinkContainer to="/campaigns/new">
            <Nav.Link>
              <Button size="sm" variant="outline-primary"> <FiPlus/> New Campaign</Button>
            </Nav.Link>
          </LinkContainer>
        </Nav> */}
      </Navbar>

      <Container className="my-3">
        <div>
          { campaigns.map(campaign => (
            <LinkContainer key={campaign.id} to={"/campaign/" + campaign.id}>
              <Card className="d-flex flex-row card-campaign">
                <div className="flex-fill">
                  <Card.Body>
                    <Card.Title as="h5">{campaign.attributes.name}</Card.Title>
                    <Card.Text>
                      {campaign.attributes.description}
                    </Card.Text>
                  </Card.Body>
                </div>

                <div className="align-self-center">
                  <Card.Body>
                    <FiChevronRight size="2em"/>
                  </Card.Body>
                </div>

              </Card>
            </LinkContainer>
          ))}
        </div>
      </Container>
    </React.Fragment>
  )
}

export default withAuthenticationRequired(Campaigns, {
  onRedirecting: () => <Loading />,
});
