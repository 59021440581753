// import React, { useState } from 'react';
import React from 'react';
import { useParams } from "react-router-dom";
import useSWR from 'swr'
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import fetcher from '../utils/fetcher'
import Loading from '../components/Loading'
import Error from '../components/Error'
import Player from '../components/Player'
// import Container from "react-bootstrap/Container"
// import Toast from "react-bootstrap/Toast"

// import {
//   d20, d12, d10, d8, d6, d4
// } from '../assets/img/dice'

const Campaign = () => {
  // eslint-disable-next-line no-unused-vars
  const { user, getAccessTokenSilently } = useAuth0();
  let { id } = useParams()

  // const [rolls, setRolls] = useState({ dice: 0, roll: 0})
  const { data, error } = useSWR([`/api/v1/campaigns/${id}`, getAccessTokenSilently], fetcher)
 
  if (error && !data) return <Error message="Unable to fetch campaign"/>
  if (!data) return <Loading/>

  const campaign = data.data
  const players = data.included.map((player) => {
    let name = player.attributes.name.split(" ")
    player.attributes.firstName = name.shift()
    player.attributes.lastName = name.join(" ")

    return player
  })

  // function roll(dice) {
  //   let roll = 1 + Math.floor(Math.random() * Math.floor(dice))
  //   setRolls({ dice, roll})
  // }

  // const dice = [
  //   { icon: d20, value: 20 },
  //   { icon: d12, value: 12 },
  //   { icon: d10, value: 10 },
  //   { icon: d8,  value: 8  },
  //   { icon: d6,  value: 6  },
  //   { icon: d4,  value: 4  },
  // ]

  return (
    <React.Fragment>

      {/* <div className="dice-results">
        <Toast>
          <Toast.Header className="px-3 py-3">
            <div className="mr-auto">
              <small>Intiative</small><br/>
              <h3 className="mb-0">20</h3>
            </div>
            <img src={d20} width="28"/>
          </Toast.Header>
        </Toast>
      </div> */}

      {/* <aside className="dice-wrapper">
        <div className="dice-rectangle">
          { dice.map(die => 
            <div key={die.value} className="dice">
              <img className="dice__icon" onClick={() => roll(die.value)} src={die.icon} width="100%"/>
              { rolls.dice == die.value && 
                <div className="rectangle dice__results">{rolls.roll}</div>
              }
            </div>            
          )}
        </div>
      </aside> */}

      <div className="board__wrapper flex-fill d-flex my-3 mx-3">
        <div className="board flex-fill">
          <iframe title="Croaker Cave" width="100%" height="100%" src={campaign.attributes.figmaUrl}></iframe>
        </div>
      </div>

      <aside className="players__wrapper">
        <div className="players">
          { players.map(player => (
            <Player key={player.id} player={player} radius={34}></Player>
          ))}
        </div>
      </aside>
    </React.Fragment>
  )
}

export default withAuthenticationRequired(Campaign, {
  onRedirecting: () => <Loading />,
});