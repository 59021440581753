import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const Player = ({ radius, player }) => {
  const progress = (player.attributes.hitPoints.current / player.attributes.hitPoints.max) * 100
  const stroke = 5
  let color = "#333"

  const normalizedRadius = radius - stroke;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;
  const id = player.attributes.firstName.toLowerCase() + '-image'
  
  if (progress > 80) {
    color = "green"
  } else if (progress > 50) {
    color = "orange"
  } else {
    color = "red"
  }

  return (
    <div className="player">
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-${id}`}>
            {player.attributes.hitPoints.current} / {player.attributes.hitPoints.max}
          </Tooltip>
        }
      >
        <a href={`https://www.dndbeyond.com/characters/${player.id}`} target="_blank" rel="noopener noreferrer">
          <svg
            height={radius * 2}
            width={radius * 2}
            >
            <defs>
              <pattern id={id} x="0" y="0" patternUnits="userSpaceOnUse" height={radius * 2} width={radius * 2}>
                <image x="0" y="0" height={radius * 2} width={radius * 2} xlinkHref={player.attributes.avatarUrl}></image>
              </pattern>
            </defs>
            <circle
              fill={"url(#" + id + ")"}
              r={ normalizedRadius }
              cx={ radius }
              cy={ radius }
              />
            <circle
              className="player__health"
              stroke={color}
              fill="transparent"
              strokeWidth={ stroke }
              strokeDasharray={ circumference + ' ' + circumference }
              style={ { strokeDashoffset } }
              r={ normalizedRadius }
              cx={ radius }
              cy={ radius }
              />
          </svg>
        </a>


      </OverlayTrigger>
      <span className="player__name">{player.attributes.firstName}</span>
    </div>
  )
}

export default Player
