import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
// import Form from "react-bootstrap/Form"

const Navigation = () => {
  const { logout, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return (
      <Navbar>
        <Navbar.Brand>Runcible</Navbar.Brand>
        <Nav>
          <LinkContainer to="/campaigns">
            <Nav.Link>
              Campaigns
            </Nav.Link>
          </LinkContainer>
  
          <LinkContainer to="/profile">
            <Nav.Link>
              Profile
            </Nav.Link>
          </LinkContainer>
        </Nav>
        <Nav className="ml-auto">
          <Nav.Link onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              }
            })
          }>
            Logout
          </Nav.Link>
          {/* <Form inline>
            <Form.Check 
              type="switch"
              id="custom-switch"
              label="Theme"
              defaultChecked={darkTheme}
              onChange={(e) => setDarkTheme(!darkTheme)}
            />
          </Form> */}
  
          <Navbar.Text className="mr-2">v{process.env.REACT_APP_VERSION}</Navbar.Text>
          {/* <LinkContainer to="/"> */}
            {/* <Nav.Link>
              <FiSidebar/>
            </Nav.Link> */}
          {/* </LinkContainer> */}
        </Nav>
      </Navbar>
    ) 
  } else {
    return null
  }
}

export default Navigation
