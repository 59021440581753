import React from 'react';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Navigation from './components/Navigation'
import Loading from './components/Loading'

import Campaigns from './pages/Campaigns'
import Campaign from './pages/Campaign'
import Profile from './pages/Profile'
import NotFound from './pages/NotFound'

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <main className="d-flex flex-column">
      <Navigation></Navigation>

      <Routes>
        <Route path="/campaign/:id" element={<Campaign/>}/>
        <Route path="/campaigns/archived" element={<Campaigns status="ARCHIVED" />}/>
        <Route path="/campaigns" element={<Campaigns status="ACTIVE" />}/>
        <Route exact path="/" element={<Navigate to="/campaigns"/>}/>
        <Route exact path="/profile" element={<Profile />}/>
        <Route element={<NotFound />}/>
      </Routes>
    </main>
  );
}

export default App;
