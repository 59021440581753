async function fetcher([url, getAccessTokenSilently]) {
  try {
    const token = await getAccessTokenSilently();

    let response = await fetch(process.env.REACT_APP_API + url, {
      headers: new Headers({
        'Authorization': `Bearer ${token}`
      })
    })
    if (response.ok) {
      return response.json()
    } else {
      throw new Error("Couldn't complete fetch")
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export default fetcher