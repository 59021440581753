import React from 'react'
import Container from "react-bootstrap/Container"

const Loading = () => {
  return (
    <Container className="my-auto text-center">
      <span>Loading...</span>
    </Container>
  )
}

export default Loading
