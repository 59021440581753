import React from 'react'
import Container from "react-bootstrap/Container"

const Error = ({message}) => {
  return (
    <Container className="my-auto text-center text-light">
      <h3>Whoops! Something went wrong...</h3>
      <div className="lead">{message}</div>
    </Container>
  )
}

export default Error
